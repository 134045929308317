import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import sty

function FaqComponent() {
  return (
    <section className="bg-black faq-section">
      <div className="container max-w-4xl px-6 py-5 mx-auto">
        <h1 className="text-3xl md:text-4xl font-semibold text-center text-white">
          <FontAwesomeIcon icon={solid('circle-question')} /> Frequently Asked Questions
        </h1>
        <div className="mt-12 space-y-8">
          <div className="tab2 border-2 rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-one" type="checkbox" name="tabs" />
            <label
              className="text-md md:text-2x1 flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-one"
            >
              What is Steady Stack?
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                <p>
                  <b>
                    <i>We are a brand. We are a platform. We are leaders.</i>
                  </b>
                </p>

                <p>
                  <b>
                    <i>The Ultimate Investor Illuminati.</i>
                  </b>
                </p>

                <p>
                  Access & Proximity to the Top Network of Investors, Resources & Exclusive Opportunities comprised of high-level Traders,
                  VC’s, Web3 Natives, Business Owners, Investors & Winners alike. Unparalleled Day 1 Utility. Future of NFTs.
                </p>

                <p>
                  <b>
                    <i>
                      On a mission to Re-Invent NFTs for a more sustainable future with new concepts & much-needed business fundamentals.
                    </i>
                  </b>{' '}
                </p>
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-ten" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-ten"
            >
              Is Steady Stack an Alpha group?{' '}
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                <p>
                  We are 100x an "alpha group". Reason being is that NO alpha group comes with the underlying DAY 1 UTILITY that Steady
                  Stack offers which puts Titans in a league of their own.
                </p>

                <p>
                  From access to our Proprietary Trading SaaS Technology to guide better entries & exits among all the priceless value
                  inside from Masterminds & AMA's with 8/9 figure leaders across Investing Niches in Trading, Investing, Business, Real
                  Estate + more.{' '}
                </p>

                <p>
                  Alpha Groups can quickly become saturated because there's no barrier protecting the group. If an Alpha group gives alpha &
                  100 members also have alpha groups, the alpha gets passed around to the point it is NO longer alpha.{' '}
                  <b>
                    With our unique value propositions from Access to our Trading SaaS & other Exclusive Opportunities to our AMA's to our
                    community driven initiatives under our leadership, there is no other ecosystem in the NFT space like the
                    Industry-Leading Steady Stack Titans.
                  </b>{' '}
                </p>

                <p>
                  <b>One-of-a-kind Investor Mastermind Illuminati.</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqComponent
