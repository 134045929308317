import './App.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import sty
import React, { useState, useEffect } from 'react'
import CarsouselComponent from './components/carousel/carousel.component'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import FaqComponent from './components/faq/faq.component'
import RainbowKitMint from './components/rainbowkit-mint/rainbowkit-mint.component'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}

const Home = () => {
  const navigate = useNavigate()

  const onClickMasterLink = () => {
    const htmlMintValEl = document.getElementById('totalMinted')?.innerHTML ?? '0'
    const totalMinted = parseInt(htmlMintValEl)
    const plural = totalMinted > 1 ? 's' : ''
    navigate('/masterclass')
  }

  return (
    <>
      <nav id="header" className="top-0 ">
        <div className="container max-w-7xl px-3 md:px-6 py-0 mx-auto">
          <div className="block">
              <div className="justify-center md:justify-start	 sm:ml-4 flex-shrink-0 items-center text-center">
                <Link to="/">
                  <img className="main_logo inline relative bottom-1 rounded-lg mt-4" src="/PNG_For_Navbar.png" alt="FluffDistrict NFT" />
                </Link>
              </div>
              
              <div className="flex-col md:flex block md:inline pull-right pr-2 sm:static sm:inset-auto sm:pr-0 ">
              <div className="pt-4 flex space-x-0 justify-center items-center">
                <div className="container mx-auto">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2">
                    <div className="flex justify-center">
                      <a
                        href="https://discordapp.com/users/1011376091384008798/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-300 bg-[#343434] hover:bg-gray-500 h-14 hover:text-white px-4 py-4 rounded-md text-sm"
                      >
                        <FontAwesomeIcon icon={brands('telegram')} /> Join Fluff Discord
                      </a>
                    </div>
                    <div className="flex justify-center">
                      <a
                        href="https://twitter.com/FluffDistrict"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-300 bg-[#343434] hover:bg-gray-500 h-14 hover:text-white px-4 py-4 rounded-md text-sm"
                      >
                        <FontAwesomeIcon icon={brands('twitter')} /> Follow Fluff Twitter
                      </a>
                    </div>
                    <div className="flex justify-center">
                      <a
                        href="https://byt.io/telos/collection/fluffdistrict"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-300 px-4 py-4 rounded-md text-sm h-14 bg-[#343434] hover:bg-gray-500 hover:text-white"
                      >
                        <img
                          src="https://byt.io/favicon.ico"
                          width="16"
                          height="16"
                          alt="Byt Logo Icon"
                          style={{ display: 'inline', borderRadius: '3px', position: 'relative', top: '-2px' }}
                        />{' '}
                        View FluffDistrict on Byt
                      </a>
                    </div>
                    <div className="flex justify-center md:justify-self-end md:place-self-end static md:absolute">
                      <ConnectButton.Custom>
                        {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
                          return (
                            <div
                              {...(!mounted && {
                                'aria-hidden': true,
                                style: {
                                  opacity: 0,
                                  pointerEvents: 'none',
                                  userSelect: 'none',
                                },
                              })}
                              className="text-gray-300 bg-[#343434] hover:bg-gray-500 hover:text-white px-4 py-4 rounded-md text-sm ml-2"
                            >
                              {(() => {
                                if (!mounted || !account || !chain) {
                                  return (
                                    <button onClick={openConnectModal} type="button">
                                      <FontAwesomeIcon icon={solid('wallet')} /> Connect Wallet
                                    </button>
                                  )
                                }

                                if (chain.unsupported) {
                                  return (
                                    <button onClick={openChainModal} type="button">
                                      Wrong network
                                    </button>
                                  )
                                }

                                return (
                                  <div style={{ display: 'inline' }}>
                                    <button onClick={openChainModal} type="button">
                                      {chain.hasIcon && (
                                        <div
                                          style={{
                                            background: chain.iconBackground,
                                            width: 12,
                                            height: 12,
                                            borderRadius: 999,
                                            overflow: 'hidden',
                                            marginRight: 4,
                                          }}
                                        >
                                          {chain.iconUrl && (
                                            <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} style={{ width: 12, height: 12 }} />
                                          )}
                                        </div>
                                      )}
                                    </button>

                                    <button onClick={openAccountModal} type="button">
                                      {account.displayName}
                                      {account.displayBalance ? ` (${account.displayBalance})` : ''}
                                    </button>
                                  </div>
                                )
                              })()}
                            </div>
                          )
                        }}
                      </ConnectButton.Custom>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </nav>

      <div className="bg-[#fff] mt-72 md:mt-24">
        <div className="container max-w-7xl px-3 md:px-6 py-5 mx-auto">
          <h1 className="main-header text-center	 font-semibold text-[#343434] mb-2">
            <img src="/telos_icon.png" height="32" width="32" className="inline" alt="Telos Logo" /> Telos Foundation Mission NFT x Byt.io Launchpad -
            FluffDistrict
          </h1>
          <h1 className="text-center sub-header	 text-[#343434] mb-2">Mark 10:45 "For even the Son of Man came not to be served but to serve, and to give his life as a ransom for many."</h1>

          <div className="flex-row md:flex mb-0 md:mb-4 mt-6">
            <div className="flex-col md:flex w-full">
              <div className="container mx-auto bg-black border border-white rounded-xl shadow border p-4">
                <RainbowKitMint />
              </div>
            </div>
          </div>
        </div>
        
      </div>




      <section className="bg-[#fff]">
        <div className="container max-w-5xl px-6 py-4 mx-auto">
          <div className="flex-row md:flex mb-4">
            <div className="flex-col md:flex w-full md:w-1/2 mr-4">
              <section className="overflow-hidden text-gray-700">
                <div className="container mx-auto ">
                  <div className="flex flex-wrap -m-1 md:-m-2">
                    <div className="flex flex-wrap w-1/2">
                      <div className="w-1/2 p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="/art2/Mock 1.png"
                        />
                      </div>
                      <div className="w-1/2 p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="/art2/Mock 2.png"
                        />
                      </div>
                      <div className="w-full p-1 md:p-2">
                        <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg" src="/art2/Mock 7.png" />
                      </div>
                    </div>
                    <div className="flex flex-wrap w-1/2">
                      <div className="w-full p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="/art2/Mock 3.png"
                        />
                      </div>
                      <div className="w-1/2 p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="/art2/Mock 4.png"
                        />
                      </div>
                      <div className="w-1/2 p-1 md:p-2">
                        <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg" src="/art2/Mock 6.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="flex-col md:flex w-full md:w-1/2 mr-4">
        <div className="">
        <img src="Sample(2)(1)(1).png" alt="Cubby Hole" className="rounded-lg w-full" style={{height: '112%', width: '110%'}}/>

        </div>
      </div>


          </div>
        </div>
      </section>

      <div className="hidden">
        <FaqComponent />
      </div>
    </>
  )
}

const NotFound = () => {
  return (
    <>
      <div>404 page not found.</div>
    </>
  )
}

export default App
